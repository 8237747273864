
$(document).ready(function() {
  magnificLightbox();
});

function magnificLightbox() {
  $('.magnific-gallery').each(function(index, el) {
    $(el).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      }
    })
  });

  $('.magnific-gallery-link').each(function(index, value) {
    var gallery = $(this);
    var galleryImages = $(this).data('items').split(',');
    var items = [];
    for (var i = 0; i < galleryImages.length; i++) {
      items.push({
        src: galleryImages[i],
        title: ''
      });
    }
    gallery.magnificPopup({
      mainClass: 'mfp-fade',
      items: items,
      gallery: {
        enabled: true,
        tPrev: $(this).data('prev-text'),
        tNext: $(this).data('next-text')
      },
      type: 'image'
    });
  });

  $('.magnific-inline').magnificPopup({
    type: 'inline',
    fixedContentPos: true
  });

  $('.magnific-iframe').magnificPopup({
    type: 'iframe'
  });
}
